<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
  >
    <div
      class="shadePanel"
      v-if="loading"
    ></div>
    <div class="early-wrap">
      <iframe
        id="commandIframe"
        :src="url"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: true,
      url: '',
      id: ''
    }
  },
  computed: {
    ...mapGetters({
      token: 'token'
    })
  },
  mounted () {
    this.id = this.$route.query.id
    this.getDPurl()
    const iframe = document.getElementById('commandIframe')
    if (iframe.attachEvent) {
      iframe.attachEvent('onload', () => {
        // iframe加载完成后要进行的操作
        this.loading = false
      })
    } else {
      iframe.onload = () => {
        // iframe加载完成后要进行的操作
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    }
  },
  methods: {
    getDPurl () {
      this.axios.post(api.getYJurl, { id: this.id }).then(res => {
        // this.url = res.data.data.url
        const arrURL = res.data.data.url.split('com/set/')
        this.url = this.$shulieDomain + arrURL[1] + '&token=' + this.token
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.early-wrap
  width 100%
  height 800px
  iframe
    width 100%
    min-height 795px
    height 100%
.shadePanel
  width 100%
  height 100%
  background #272930
  position absolute
  top 122px
</style>
